<template>
    <layout>
        <div class="row mb-3 align-items-center">
            <div class="col">
                <PageHeader :title="title" />
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="main-search-card ">
                    <div class="mb-3">
                        <div class="row gx-2">
                            <div class="col">
                                <h3 class="my-1 font-size-18 text-dark-cstm">Add More Journals</h3>
                                <div class="col">
                                    <table class="table mb-0 table-bordered">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Service</th>
                                                <td>
                                                    <div class="d-flex flex-wrap align-items-center"
                                                        v-if="story.submissionPackageId == null && story.fictionPackageId == null && story.editingPackageId == null">
                                                        <h6 class="mb-0">Currently you have no active package</h6>
                                                    </div>
                                                    <div class="d-flex flex-wrap align-items-center" v-if="story.submissionPackageId && story.submissionPackageId != 'null' &&
                                                        story.submissionPackageId != null
                                                    ">
                                                        <h6 class="mb-0">Submission Packages :</h6>
                                                        <span class="d-inline-block ms-2 me-1">{{
                                                            story.submissionPackage.title }}
                                                            ,<i class="mdi mdi-circle-d"></i></span>
                                                        <span class="text-dark-cstm font-weight-500">${{
                                                            story.submissionPackage.amount }}</span>
                                                    </div>
                                                    <div class="d-flex flex-wrap align-items-center" v-if="story.fictionPackageId && story.fictionPackageId != 'null' &&
                                                        story.fictionPackageId != null
                                                    ">
                                                        <h6 class="mb-0">Flash Fiction Packages :</h6>
                                                        <span class="d-inline-block ms-2 me-1">{{
                                                            story.fictionPackage.title }} ,<i
                                                                class="mdi mdi-circle-d"></i></span>
                                                        <span class="text-dark-cstm font-weight-500">${{
                                                            story.fictionPackage.amount
                                                        }}</span>
                                                    </div>
                                                    <div class="d-flex flex-wrap align-items-center" v-if="story.editingPackageId && story.editingPackageId != 'null' &&
                                                        story.editingPackageId != null
                                                    ">
                                                        <h6 class="mb-0">Editing Packages :</h6>
                                                        <span class="d-inline-block ms-2 me-1">{{
                                                            story.editingPackage.title }} ,<i
                                                                class="mdi mdi-circle-d"></i></span>
                                                        <span class="text-dark-cstm font-weight-500">${{
                                                            story.editingPackage.amount
                                                        }}</span>
                                                    </div>
                                                    <!-- <template> -->
                                                    <div class="d-flex flex-wrap align-items-center"
                                                        v-for="service in extraServices" :key="service.id">
                                                        <h6 class="mb-0"
                                                            :class="{ 'text-secondary': service.status == 'Pending' }">
                                                            Extra Packages :</h6>
                                                        <span class="d-inline-block ms-2 me-1"
                                                            :class="{ 'text-secondary': service.status == 'Pending' }">{{
                                                                service.title }} ,<i class="mdi mdi-circle-d"></i></span>
                                                        <span class="text-dark-cstm font-weight-500"
                                                            :class="{ 'text-secondary': service.status == 'Pending' }">${{
                                                                service.amount
                                                            }}</span>
                                                    </div>
                                                    <div class="d-flex flex-wrap align-items-center"
                                                        v-for="(service, index) in addOnServices" :key="index">
                                                        <h6 class="mb-0">
                                                            Extra Packages :</h6>
                                                        <span class="d-inline-block ms-2 me-1">{{
                                                            service.title }} ,<i class="mdi mdi-circle-d"></i></span>
                                                        <span class="text-dark-cstm font-weight-500">${{
                                                            service.amount
                                                        }}</span>
                                                        <!-- <sup class="text-theme" v-if="service.status == 'Pending'">(Pending Payment)</sup> -->
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Submission Strategy</th>
                                                <td v-if="story.submissionStrategy">{{ story.submissionStrategy }}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Strategy Notes</th>
                                                <td v-if="story.strategyNotes">{{ story.strategyNotes }}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Story Information</th>
                                                <td v-if="story.storyInfo">{{ story.storyInfo }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <!-- </template> -->

                                </div>
                            </div>

                            <div class="col">
                                <div class="searchBarStyle topBarCstmTable  position-relative">
                                    <input type="text" v-model="searchValueTable" class="form-control"
                                        placeholder="Search...">
                                    <span class="iconSearch bx bx-search-alt"></span>
                                </div>
                            </div>
                            <div class="col-auto">
                                <button @click="isshow = !isshow" class="btn btn-theme">Advance Search</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Transition name="fade">
                    <div class="main-group-search p-3 border mb-3" v-if="isshow">
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <label class="form-label">Journals Rating</label>
                                <div class="multipleSelectCstm">
                                    <Select2 v-model="journalRatingMain" placeholder="Select Rating"
                                        :settings="{ multiple: true }" :options="journalRatingOptions" />
                                </div>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label class="form-label">Acceptance Percent</label>
                                <div class="multipleSelectCstm">
                                    <Select2 v-model="AcceptPreMain" placeholder="Select Acceptance Percent"
                                        :settings="{ multiple: true }" :options="AcceptPreOptions" />
                                </div>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label class="form-label">Volume</label>
                                <div class="single-select2-cstm">
                                    <Select2 v-model="volumeMain" placeholder="Select Volume"
                                        :options="volumeOptions" />
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="col-md-6 mb-3">
                                    <label class="labelCheckboxCstm">
                                        <input type="checkbox" name="storyCheckbox" :true-value="true"
                                            :false-value="false" v-model="showAll" @change="update" />
                                        <span class="checkmark pe-1">
                                            <i class="mdi mdi-checkbox-blank-outline font-size-20 text-theme"></i>
                                            <i class="mdi mdi-checkbox-marked-outline font-size-20 text-theme"></i>
                                        </span>
                                        Show all open Journals
                                    </label>
                                </div>
                                <div class="row mt-3 gx-2 justify-content-end">
                                    <div class="col-auto">
                                        <button class="btn btn-light" @click="getJournal()">Reset</button>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn btn-theme" @click="filterData()">Apply</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
                <EasyDataTable ref="myTable" @click="copyTable" v-model:items-selected="itemsSelected" show-index
                    :headers="headers" :items="tableItems" :search-value="searchValueTable" border-cell
                    theme-color="#3aafa9" :rows-per-page="10" buttons-pagination table-class-name="table-custom-style">
                    <template #item-journalMain="{ jrnlName, rating, matchScore }">
                        <span class="badge badge-soft-success">Match Percent: {{ matchScore }}%</span>
                        <span class="text-dark-cstm d-block">
                            {{ jrnlName }}
                        </span>
                        <div class="d-flex">
                            <template v-for="jRating in parseInt(rating.slice(0, 1))" :key="jRating">
                                <i :class="`mdi mdi-star font-size-13 text-warning`"></i>
                            </template>

                            <template v-for="jRating in 5 - parseInt(rating.slice(0, 1))" :key="jRating">
                                <i :class="`mdi mdi-star-outline font-size-13 text-warning`"></i>
                            </template>
                        </div>

                    </template>
                    <template #item-possibleThemes="{ possibleThemes }">
                        <p v-if="possibleThemes == 1">Yes</p>
                        <p v-else>No</p>
                    </template>
                    <template #item-acceptance="{ acceptance }">
                        <p>{{ (acceptance * 100).toFixed(2) }}%</p>
                    </template>

                </EasyDataTable>
                <div class="mt-3 text-end">
                    <button type="button" class="btn btn-theme" @click="addInList">Add to List</button>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "../layouts/main";
import PageHeader from "@/components/admin/header/page-header";
import EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import Select2 from 'vue3-select2-component';
import axios from 'axios';
import CryptoJS from "crypto-js";

export default ({
    page: {
        title: "Add More Journal",
        meta: [
            {
                name: "description",
                content: 'description',
            },
        ],
    },

    data() {

        return {
            title: "",
            showAll: false,
            url: process.env.VUE_APP_URL,
            searchValueTable: "",
            isshow: false,
            fetchData: false,
            journalRatingMain: [],
            volumeMain: "",
            AcceptPreMain: "",
            AcceptPreOptions: ['<1%', '1-3%', '3-5%', '>5%'],
            journalRatingOptions: ['1 Stars', '2 Stars', '3 Stars', '4 Stars', '5 Stars'],
            volumeOptions: ['1', '2', '3', '4', '5'],
            itemsSelected: [],
            headers: [
                { text: "Journals", value: "journalMain" },
                { text: "Year", value: "yearOfInception" },
                { text: "Accep. %", value: "acceptance" },
                { text: "Volume", value: "volume" },
                { text: "Style", value: "permanentThemes" },
                { text: "Flash", value: "flash" },
                { text: "Possible Themes", value: "possibleThemes" },
                { text: "Themes", value: "theme" },
            ],
            tableItems: [],
            unmutableTableItems: [],
            journals: [],
            rejectJournals: [],
            otherJournals: [],
            unmutableJournal: [],
            doNotSubmitList: [],
            selectedJournals: [],
            rejectMainJournals: [],
            rejectedJournals: [],
            story: [],
            storyScore: [],
            bonusScore: 0,
            algoRun: false,
            extraServices: [],
            addOnServices: []

        }
    },
    components: {
        Layout,
        PageHeader,
        EasyDataTable,
        Select2
    },
    beforeMount() {
        if (!this.fetchData) {
            this.getStory();
            // this.getAddMoreJournal()
        }

    },
    activated() {
        if (this.fetchData) {
            this.getStory();
            // this.getAddMoreJournal()
        }

    },
    // add New functionality
    watch: {
        $route(to, from) {
            this.resetInputField();
            if (to.params.id !== from.params.id && to.name == 'addMoreJournal') {
                this.getStory();
                // this.getAddMoreJournal()
            }
        }
    },
    methods: {
        // getAddMoreJournal() {
        //     axios.post(this.url + 'api/getAddMoreJournal', { story_id: this.decode(this.$route.params.id) }).then(res => {
        //         this.selectedJournals = res.data;
        //         // this.unmutableselectedJournals = res.data;
        //         // this.getJournalByMatch();
        //     });
        // },
        getStory() {
            let data = new FormData();
            data.append("id", this.decode(this.$route.params.id));

            axios.post(this.url + "api/getSingleStory", data).then((res) => {
                // this.story_id = res.data.story.id
                this.story = res.data.story;
                this.extraServices = res.data.extraServices
                this.addOnServices = res.data.addOnServices;
                if (res.data.storyScore !== null) {
                    this.algoRun = true
                    this.storyScore = res.data.storyScore;
                }
                this.getDoNotSubmitJournals()
            });
        },
        getDoNotSubmitJournals() {
            axios.post(this.url + 'api/getDoNotSubmitJournals', { id: this.story.user_id, storyId: this.decode(this.$route.params.id) }).then(res => {
                this.doNotSubmitList = res.data.journals
                this.rejectMainJournals = res.data.otherJournals
                this.rejectedJournals = res.data.rejectedJournals
                this.selectedJournals = res.data.selectedJournals
                this.fetchData = true;
                this.getJournal();
            })
        },
        getJournal() {
            this.journalRatingMain = [];
            this.AcceptPreMain = "";
            this.volumeMain = "";
            let data = new FormData()
            data.append('storyId', this.decode(this.$route.params.id))
            axios.post(this.url + 'api/getJournalsWithScore', data).then(response => {
                let data = new FormData()
                data.append('id', this.decode(this.$route.params.id))
                axios.post(this.url + 'api/getStorySubmissionResult', data).then(res => {
                    let submittedJournals = res.data
                    let allJournals = response.data
                    this.unmutableJournal = filterByReference(allJournals, submittedJournals)
                    this.unmutableJournal = this.unmutableJournal.filter((element) => {
                        return !this.doNotSubmitList.map((item) => item.id).includes(element.id);
                    });
                    // this.unmutableJournal = this.unmutableJournal.filter((element) => {
                    //     return !this.rejectMainJournals.map((item) => item.id).includes(element.id);
                    // });
                    // ----after 4 months rejected Journals
                    let fourMonthsAgo = new Date();
                    fourMonthsAgo.setMonth(fourMonthsAgo.getMonth() - 4);
                    this.unmutableJournal = this.unmutableJournal.filter(element => {
                        let rejectJournal = this.rejectedJournals.find(item => item.journal_id == element.id);
                        if (rejectJournal && rejectJournal.updated_at) {
                            if (rejectJournal.story_id == this.decode(this.$route.params.id)) {
                                return false;
                            } else {
                                let rejectJournalDate = new Date(rejectJournal.updated_at);
                                return rejectJournalDate <= fourMonthsAgo;
                            }
                        }
                        return true;
                    });
                    this.unmutableJournal.sort((a, b) => (a.matchScore < b.matchScore) ? 1 : -1)
                    this.unmutableTableItems = this.unmutableJournal;
                    if (this.algoRun == true) {
                        this.getSuggestedJournals()
                    }
                })
            })
            const filterByReference = (arr1, arr2) => {
                let res = [];
                res = arr1.filter((el) => {
                    return !arr2.find((element) => {
                        if (
                            element.status == 0 ||
                            element.status == 1 ||
                            element.round >= 3
                        ) {
                            return element.journal_id === el.id;
                        }
                    });
                });
                return res;
            };

        },
        getSuggestedJournals() {
            this.journals = this.unmutableTableItems
            let journals = this.journals;
            // this.suggestedJournals = []
            this.otherJournals = []
            for (var i = 0; i < this.journals.length; i++) {
                this.journals[i].matchScore = 0;
                let journalScore = this.journals[i].journal_scores;
                let storyScore = this.storyScore;
                if (journals[i].acceptancePercent == 5 && journals[i].submissionVolume == 5) {
                    this.bonusScore = 10 / 100;
                } else if (
                    (journals[i].acceptancePercent == 5 &&
                        journals[i].submissionVolume == 4) ||
                    (journals[i].acceptancePercent == 4 &&
                        journals[i].submissionVolume == 5)
                ) {
                    this.bonusScore = 9 / 100;
                } else if (
                    (journals[i].acceptancePercent == 5 &&
                        journals[i].submissionVolume == 3) ||
                    (journals[i].acceptancePercent == 4 &&
                        journals[i].submissionVolume == 4)
                ) {
                    this.bonusScore = 8 / 100;
                } else if (
                    (journals[i].acceptancePercent == 5 &&
                        journals[i].submissionVolume == 2) ||
                    (journals[i].acceptancePercent == 4 &&
                        journals[i].submissionVolume == 3)
                ) {
                    this.bonusScore = 7 / 100;
                } else if (
                    (journals[i].acceptancePercent == 5 &&
                        journals[i].submissionVolume == 1) ||
                    (journals[i].acceptancePercent == 4 &&
                        journals[i].submissionVolume == 2)
                ) {
                    this.bonusScore = 6 / 100;
                } else if (
                    (journals[i].acceptancePercent == 4 &&
                        journals[i].submissionVolume == 1) ||
                    (journals[i].acceptancePercent == 3 &&
                        journals[i].submissionVolume <= 6 &&
                        journals[i].submissionVolume >= 1) ||
                    (journals[i].acceptancePercent == 2 &&
                        journals[i].submissionVolume == 1)
                ) {
                    this.bonusScore = 5 / 100;
                } else if (
                    (journals[i].acceptancePercent == 2 &&
                        journals[i].submissionVolume == 2) ||
                    (journals[i].acceptancePercent == 1 &&
                        journals[i].submissionVolume == 1)
                ) {
                    this.bonusScore = 4 / 100;
                } else if (
                    (journals[i].acceptancePercent == 2 &&
                        journals[i].submissionVolume == 3) ||
                    (journals[i].acceptancePercent == 1 &&
                        journals[i].submissionVolume == 2)
                ) {
                    this.bonusScore = 3 / 100;
                } else if (
                    (journals[i].acceptancePercent == 2 &&
                        journals[i].submissionVolume == 4) ||
                    (journals[i].acceptancePercent == 1 &&
                        journals[i].submissionVolume == 3)
                ) {
                    this.bonusScore = 2 / 100;
                } else if (
                    (journals[i].acceptancePercent == 2 &&
                        journals[i].submissionVolume == 5) ||
                    (journals[i].acceptancePercent == 1 &&
                        journals[i].submissionVolume == 4)
                ) {
                    this.bonusScore = 1 / 100;
                } else {
                    this.bonusScore = 0 / 100;
                }
                let mainScore =
                    Math.abs((journalScore.quality - storyScore.quality) * 3) +
                    Math.abs(journalScore.stylized - storyScore.stylized) +
                    Math.abs(journalScore.difficulty - storyScore.difficulty) +
                    Math.abs(journalScore.topical - storyScore.topical) +
                    Math.abs(journalScore.experimental - storyScore.experimental) +
                    Math.abs(journalScore.humor - storyScore.humor) +
                    Math.abs(journalScore.strange - storyScore.strange) +
                    Math.abs(journalScore.dark - storyScore.dark) +
                    Math.abs(journalScore.international - storyScore.international) +
                    Math.abs(journalScore.margins - storyScore.margins) +
                    Math.abs(journalScore.historical - storyScore.historical) +
                    Math.abs(journalScore.long - storyScore.long) +
                    Math.abs(journalScore.short - storyScore.short);
                let matchScore = (0.9 + -0.02 * mainScore + this.bonusScore) * 100;
                this.journals[i].matchScore = Math.round(
                    (journals[i].yearOfInception == new Date().getFullYear() ||
                        journals[i].yearOfInception == new Date().getFullYear() - 1
                        ? 5
                        : 0) + matchScore
                );
                //  add story_Id add more journal list
                this.journals[i].story_id = this.decode(this.$route.params.id);
            }
            this.journals.sort((a, b) => (a.matchScore < b.matchScore) ? 1 : -1)

            let selectedIds = this.selectedJournals.flat().map((item) => item.id);
            let journals1 = this.journals.filter((journal) => {
                return !selectedIds.includes(journal.id);
            });
            let rejectIds = this.rejectedJournals.flat().map((item) => item.id);
            let journals2 = this.journals.filter((journal) => {
                return !rejectIds.includes(journal.id);
            });
            this.journals = this.journals.filter((journal) => {
                return !rejectIds.includes(journal.id);
            });
            journals2.forEach((journal) => {
                this.rejectJournals.push(journal)
            })
            journals1.forEach((journal) => {
                this.otherJournals.push(journal);
            });

        },
        update() {
            const rejectJournals = this.rejectJournals
            const allJournals = this.otherJournals
            if (this.showAll) {
                const newJournals = allJournals.concat(rejectJournals)
                const uniqueData = Array.from(newJournals.reduce((map, item) => {
                    map.set(item.id, item);
                    return map;
                }, new Map()).values());
                this.tableItems = uniqueData;
                this.unmutableTableItems = uniqueData;
            } else {
                this.tableItems = allJournals
                this.unmutableTableItems = allJournals
            }
            this.tableItems.sort((a, b) => (a.matchScore < b.matchScore) ? 1 : -1)
            this.unmutableTableItems.sort((a, b) => (a.matchScore < b.matchScore) ? 1 : -1)
            this.filterData()
        },
        addInList() {
            if (this.itemsSelected.length > 0) {
                axios.post(this.url + 'api/addMoreJournal', this.itemsSelected).then(res => {
                    this.itemsSelected = [];
                    const story_id = res.data.flat().map(story => story.story_id);
                    this.$store.state.isAccepted = true
                    this.$store.state.notificationData = 'Journal Added Successfully'
                    setTimeout(() => {
                        this.$store.state.isAccepted = false
                        this.$store.state.notificationData = null
                    }, 2500)
                    this.$router.push({ path: `/single-story/${this.encode(story_id[0])}` });
                })
            } else {
                this.$router.go(-1)
            }
            // this.$store.state.selectedJournals = this.itemsSelected
            // this.$router.go(-1)
        },
        filterData() {
            // this.tableItems = this.unmutableTableItems
            let items = this.unmutableTableItems
            if (this.journalRatingMain.length > 0) {
                items = items.filter(journal => this.journalRatingMain.includes(journal.rating));

            }
            if (this.AcceptPreMain) {
                items = items.filter(journal => {
                    return this.AcceptPreMain.some(acceptance => {
                        if (acceptance === '<1%') {
                            return (journal.acceptance * 100).toFixed(2) < 1
                        }
                        if (acceptance === '1-3%') {
                            return (journal.acceptance * 100).toFixed(2) >= 1 && (journal.acceptance * 100).toFixed(2) < 3
                        }
                        if (acceptance == '3-5%') {
                            return (journal.acceptance * 100).toFixed(2) >= 3 && (journal.acceptance * 100).toFixed(2) <= 5
                        }
                        if (acceptance == '>5%') {
                            return (journal.acceptance * 100).toFixed(2) > 5
                        }
                        return false;
                    })
                })


            }
            if (this.volumeMain) {

                items = items.filter(journal => journal.submissionVolume == this.volumeMain)
            }
            // else if (this.journalRatingMain && this.AcceptPreMain) {
            //   this.journalRatingMain.forEach(function (rating) {
            //     data.push(items.filter(journal => journal.rating == rating))
            //   });
            //   let datas = data.reduce((acc, el) => acc.concat(el), [])
            //   this.tableItems = datas.filter(journal => journal.acceptancePercent >= 3 && journal.acceptancePercent <= 5)

            // } else if (this.AcceptPreMain && this.volumeMain) {
            //   this.tableItems = this.tableItems.filter(journal => journal.acceptancePercent >= 3 && journal.acceptancePercent <= 5 && journal.submissionVolume == this.volumeMain);
            // } else if (this.journalRatingMain && this.volumeMain) {
            //   this.journalRatingMain.forEach(function (rating) {
            //     data.push(items.filter(journal => journal.rating == rating))
            //   });
            //   let datas = data.reduce((acc, el) => acc.concat(el), [])
            //   this.tableItems = datas.filter(journal => journal.submissionVolume == this.volumeMain)

            // } else if (this.journalRatingMain) {
            //   this.journalRatingMain.forEach(function (rating) {
            //     data.push(items.filter(journal => journal.rating == rating))
            //   });
            //   this.tableItems = data.flat(1)

            // } else if (this.AcceptPreMain == '<1%') {
            //   this.AcceptPreMain.forEach(function () {
            //     data.push(items.filter(journal => journal.acceptancePercent <= 1))
            //   });
            //   this.tableItems = data.flat(1)
            //   // this.tableItems = this.tableItems.filter(journal => journal.acceptancePercent >= 3 && journal.acceptancePercent <= 5)


            // } else if (this.volumeMain) {
            //   this.tableItems = this.tableItems.filter(journal => journal.submissionVolume == this.volumeMain)

            // }
            this.tableItems = items
        },
        encode(id) {
            return encodeURIComponent(
                CryptoJS.AES.encrypt(String(id), "Secret Passphrase")
            );
        },
        decode(id) {
            return decodeURIComponent(
                CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(
                    CryptoJS.enc.Utf8
                )
            );
        },
        resetInputField() {
            this.showAll = false
            this.searchValueTable = ""
            this.isshow = false
            this.fetchDat = false
            this.journalRatingMain = []
            this.volumeMain = ""
            this.AcceptPreMain = ""
            this.AcceptPreOptions = ['<1%', '1-3%', '3-5%', ' >5%']
            this.journalRatingOptions = ['1 Stars', '2 Stars', '3 Stars', '4 Stars', '5 Stars']
            this.volumeOptions = ['1', '2', '3', '4', '5']
            this.itemsSelected = []
            this.tableItems = []
            this.unmutableTableItems = []
            this.journals = []
            this.rejectJournals = []
            this.otherJournals = []
            this.unmutableJournal = []
            this.doNotSubmitList = []
            this.selectedJournals = []
            this.rejectMainJournals = []
            this.rejectedJournals = []
            this.story = []
            this.storyScore = []
            this.bonusScore = 0
            this.algoRun = false
        },
    }

})
</script>